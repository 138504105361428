/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Fade from 'react-reveal/Fade';

import { Splide, SplideSlide } from '@splidejs/react-splide';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default function AllTeam({ data }) {
  return (
    <section className="container mx-0 sm:mx-auto ">
      <Fade bottom delay={200}>
        <Splide
          options={{
            type: 'loop',
            autoplay: true,
            perPage: 1,
            gap: '1rem',
            breakpoints: {
              640: {
                perPage: 1,
              },
              768: {
                perPage: 2,
              },
            },
          }}
        >
          {data.map((item) => (
            <SplideSlide key={item.id}>
              <div
                className="flex justify-center items-center"
                style={{
                  height: '350px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="flex flex-col w-44 h-60 sm:w-56 sm:h-72 rounded-xl shadow-xl border border-light-theme-purple justify-center transform transition duration-500 hover:scale-105">
                  <div className="flex justify-center xl:mb-5">
                    <img
                      src={item.imageUrl}
                      alt="Team Member"
                      className="flex w-32 h-32 rounded-full object-cover"
                    />
                  </div>
                  <h2 className="text-theme-yellow text-center text-xl">
                    {item.name}
                  </h2>
                  <p className="font-light text-gray-400 text-center mb-3">
                    {item.position}
                  </p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Fade>
    </section>
  );
}
